<!--
 * @Author: zl
 * @Date: 2021-12-21 16:16:48
 * @LastEditTime: 2022-02-24 10:31:22
 * @LastEditors: zl
 * @Description: 移交审核
-->
<template>
	<!-- 提交对象 -->
	<el-dialog title="选择提交对象"
			   :visible.sync="auditPersonObj.show"
			   width="1000px"
			   :close-on-click-modal="false"
			   append-to-body
			   @close="closeDia"
			   center>
		<el-tabs v-model="activeTab"
				 @tab-click="tabClick">
			<el-tab-pane label="企业内部人员"
						 name="0"></el-tab-pane>
			<el-tab-pane label="企业外部人员"
						 name="1"></el-tab-pane>
		</el-tabs>
		<el-row>
			<el-input v-model="searchContent"
					  @input="getAuditPersonList(0)"
					  :placeholder="this.activeTab == '0' ? '请输入姓名搜索...' : '请输入姓名、单位名称搜索...'">
			</el-input>
		</el-row>
		<vxe-table align="center"
				   auto-resize
				   border
				   class="mt15"
				   :data="auditPersonObj.table"
				   key="1002"
				   height="400px"
				   highlight-current-row
				   highlight-hover-row
				   show-overflow="tooltip"
				   show-header-overflow="tooltip">
			<vxe-table-column type="seq"
							  width="70"
							  title="序号"></vxe-table-column>
			<vxe-table-column field="sname"
							  title="姓名"></vxe-table-column>
			<vxe-table-column field="phone"
							  title="手机号"></vxe-table-column>
			<vxe-table-column field="shortName"
							  title="所属单位"
							  header-align="center"
							  align="left"
							  v-if="activeTab == '1'">
				<template #default="{ row }">
					<el-tooltip effect="dark"
								:content="row.tenantName"
								placement="top">
						<span class="vxe-cell--label">{{ row.shortName }}</span>
					</el-tooltip>
				</template>
			</vxe-table-column>
			<vxe-table-column field="limitName"
							  title="单位类型"
							  v-if="activeTab == '1'"></vxe-table-column>
			<vxe-table-column title="操作"
							  width="120">
				<template #default="{ row }">
					<el-button :type="row.id == selectPerson.id ? 'primary' : ''"
							   plain
							   round
							   class="table-btn"
							   @click="selectPersonal(row)">
						<i v-if="row.id == selectPerson.id"
						   class="el-icon-check"></i> 选择
					</el-button>
				</template>
			</vxe-table-column>
		</vxe-table>
		<vxe-pager align="center"
				   :page-size="auditPersonObj.size"
				   :current-page="auditPersonObj.current"
				   :total="auditPersonObj.total"
				   :layouts="['PrevPage', 'JumpNumber', 'NextPage', 'Sizes']"
				   @page-change="handlePersonChange"></vxe-pager>
		<div slot="footer">
			<el-button class="btn"
					   @click="auditPersonObj.show = false">取 消</el-button>
			<el-button class="btn btn-blue"
					   type="primary"
					   :loading="auditPersonObj.loading"
					   @click="doHandOver">确 定</el-button>
		</div>
	</el-dialog>
</template>
<script>
import * as HomeAPI from '@/api/home/homeIndex'
export default {
	components: {},
	props: {
		rowData: {
			type: Object,
			default: () => {
				return {}
			},
		},
		auditForm: {
			type: Object,
			default: () => {
				return {}
			},
		},
		doType: {
			type: String,
			default: 'list',
		},
	},
	data() {
		return {
			auditPersonObj: {
				table: [],
				loading: false,
				total: 0,
				size: 10,
				current: 1,
				show: false,
			},
			searchContent: '',
			activeTab: '0',
			selectPerson: {},
		}
	},
	created() {
		this.getAuditPersonList(1) // 获取审批人员
	},
	methods: {
		// 获取审批人员
		getAuditPersonList(first) {
			HomeAPI.selectApproveUserList({
				size: this.auditPersonObj.size,
				current: this.auditPersonObj.current,
				busId: this.rowData.busId,
				isCurrentDept: this.activeTab,
				searchContent: this.searchContent,
				projectId: this.rowData.projectId,
				recordId: this.rowData.id,
			}).then((res) => {
				this.auditPersonObj.table = res.data.records
				this.auditPersonObj.total = parseInt(res.data.total)
				if (first && res.data.records.length > 0) {
					this.selectPerson = res.data.records[0]
					return
				}
				this.selectPerson = {}
			})
		},

		// 选中人员
		selectPersonal(data) {
			this.selectPerson = this.$utils.clone(data, true)
		},

		// tabs click
		tabClick() {
			this.selectPerson = {}
			this.getAuditPersonList(1)
		},

		// 分页处理
		handlePersonChange({ currentPage, pageSize }) {
			this.auditPersonObj.size = pageSize
			this.auditPersonObj.current = currentPage
			this.getAuditPersonList()
		},

		// 移交
		doHandOver() {
			if (!this.selectPerson.id) {
				this.$message.error('请选择移交人员')
				return
			}
			this.auditPersonObj.loading = true
			let params = {
				id: this.rowData.id,
				auditUserId: this.selectPerson.id,
				auditTenantId: this.selectPerson.tenantId,
				auditLimitId: this.selectPerson.limitId,
			}
			if (this.doType == 'list') {
				params.status = '1001026041'
			} else {
				params.status = this.auditForm.status
				params.remark = this.auditForm.remark
				params.reasons = this.auditForm.reasons
				params.approvalAmount = this.auditForm.approvalAmount || ''
				params.payRate = this.auditForm.payRate || ''
				params.finishVal = this.auditForm.finishVal || ''
			}

			HomeAPI.saveTransferInfo(params).then((res) => {
				this.closeDia(true)
			})
		},

		// 弹出关闭
		closeDia(data) {
			this.$emit('closeDia', data)
		},
	},
	computed: {},
}
</script>
<style lang='less' scoped>
.btn {
	padding: 0 50px;
}
</style>
