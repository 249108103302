/**
 * 描述： 首页接口管理
 * 录入人： 王帅
 */
import request from '../request.js'


/**
 * 描述： 统计汇总 - 顶部5块
 * 接口管理员： 崔北超
 * 参数：{}
 */
export const getHomeData = function (data) {
    return request({
        url: 'busProject/getHomeStatistics',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 审计动态-分页接口
 * 接口管理员： 崔北超
 * 参数：
 {
    issues: '',         // 相关单位
    projectName: '',         // 项目名称
    userId: '',         // 发起人id
    deptId: '',         // 发起单位
    unitType: '',         // 单位类型
    projectId: '',      // 项目id  审计管理为空
    current: '',         // 当前页
    size: '',         // 每页条数
 }
 */
export const getAuditData = function (data) {
    return request({
        url: 'operateRecord/selctInfoByPage',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 审计动态-列表页查询条件
 * 接口管理员： 崔北超
 * 参数：{ projectId: '' }
 */
export const getQueryList = function (data) {
    return request({
        url: 'operateRecord/getUserInfos',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述： 校验进入项目权限
 * 接口管理员： 崔北超
 * 参数：{ projectId: '' }
 */
export const getAuditWorkPower = function (data) {
    return request({
        url: '/sysStation/getAuditWorkPower',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
* 描述：查询单位列表数据
* 接口管理员：崔北超
* 参数： 
{
    "tenantId": , -- --租户ID
    "size": 10,
    "current": 1,
    "status": ""-- --审核状态
}
*/
export const selectApprovalData = function (data) {
    return request({
        url: 'approveRecord/selectAgencyMattersToHome',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: true
        },
    })
}
/**
 * 描述： 获取审批人员接口
 * 接口管理员： 崔北超
 * 参数：{ 
 * size 
 current 
 busId 业务id 
 isCurrentDept 是否获取企业内部人员 0 是 1 否
 searchContent 搜索的内容
 projectId 必须 项目id
 *  }
 */
export const selectApproveUserList = function (data) {
    return request({
        url: '/approveRecord/selectApproveUserList',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}
/**
 * 描述： 获取审批人员接口
 * 接口管理员： 崔北超
 * 参数：{ 
 id 审批数据id
 auditUserId 审批人id
 auditTenantId 审批人所属租户id
 auditLimitId 审批人租户所属主体
 *  }
 */
export const saveTransferInfo = function (data) {
    return request({
        url: '/approveRecord/saveTransferInfo',
        method: 'post',
        data,
        requestConfig: {
            needTenantId: false
        },
    })
}

/**
 * 描述：获取java容器时间等
 * 接口管理员： 崔北超
 * 参数：{ 
 *  }
 */
export const getContainerParams = function (data) {
    return request({
        url: '/busProject/getContainerParams',
        method: 'post',
        data,
    })
}

/**
 * 描述： 区域块 - 项目管理与统计
 * 接口管理员： 崔北超
 * 参数：{ 
 *  }
 */
export const selectProjectStatistics = function (data) {
    return request({
        url: '/busProject/selectProjectStatistics',
        method: 'post',
        data,
    })
}

/**
 * 描述： 区域块 - 项目管理与投资
 * 接口管理员： 俞祥东
 * 参数：{ 
 *  }
 */
export const statisticsProjectScheduleAndInvestment = function (data) {
    return request({
        url: 'auditPlanValue/statisticsProjectScheduleAndInvestment',
        method: 'post',
        data,
    })
}

/**
 * 描述： 区域块 - 项目管理与投资
 * 接口管理员： 俞祥东
 * 参数：{ 
 *  }
 */
export const statisticsProjectScheduleAndInvestmentForDeclare = function (data) {
    return request({
        url: 'auditPlanValue/statisticsProjectScheduleAndInvestmentForDeclare',
        method: 'post',
        data,
    })
}


/**
 * 描述： 获取个人自定义列过滤数据
 * author： 郑志会
 * 
 */
export const getSelfData = function (data) {
    return request({
        url: '/opeSelfColumns/getSelfData',
        method: 'post',
        data
    })
}

/**
 * 描述： 保存个人自定义列过滤数据
 * author： 郑志会
 * 
 */
export const saveSelfColumns = function (data) {
    return request({
        url: '/opeSelfColumns/saveSelfColumns',
        method: 'post',
        data
    })
}

/**
 * 描述： 获取全部的参数配置
 * author： 崔北超
 * 
 */
export const selectAllUrl = function (data) {
    return request({
        url: '/sysParameter/selectAll',
        method: 'post',
        data
    })
}

/**
 * 描述： 获取运维人员配置
 * 接口管理员： 崔北超
 */
export const getUserMaintenanceRole = function (data) {
    return request({
        url: '/sysUserMaintenanceRole/getUserMaintenanceRole',
        method: 'post',
        data,
    })
}

/**
 * 描述： 配置运维人员角色
 * 接口管理员： 崔北超
 */
export const saveUserMaintenanceRole = function (data) {
    return request({
        url: '/sysUserMaintenanceRole/saveUserMaintenanceRole',
        method: 'post',
        data,
    })
}

/**
 * 获取运维角色操作日志
 * 接口管理员：陈实
 */
export const getMaintenanceOperateRecordList = function (data) {
    return request({
        url: '/sysMaintenanceOperateRecord/getMaintenanceOperateRecordList',
        method: 'post',
        data,
    })
}

/**
 * 获取模块名称和操作类型
 * 接口管理员：陈实
 */
export const getMaps = function (data) {
    return request({
        url: '/sysMaintenanceOperateRecord/getMaps',
        method: 'post',
        data,
    })
}